@tailwind base;
@tailwind components;
@tailwind utilities;

@css {
  @layer base {
  }

  @layer components {
  }

  @layer utilities {
  }
}

.custom-gradient-text-primary {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-primaryl to-primaryr;
}
.custom-gradient-text-secondary {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-secondaryl to-secondaryr;
}
.custom-gradient-primary {
  @apply bg-gradient-to-r from-primaryl to-primaryr;
}
.custom-gradient-secondary {
  @apply bg-gradient-to-r from-secondaryl to-secondaryr;
}
.custom-gradient-primary-hover {
  @apply from-primaryl to-primaryr bg-gradient-to-br transition-all hover:from-secondaryl hover:to-secondaryr cursor-pointer;
}
.custom-gradient-secondary-hover {
  @apply from-secondaryl to-secondaryr bg-gradient-to-br transition-all hover:from-primaryl hover:to-primaryr cursor-pointer;
}

* {
  @apply text-text-main;
}

.active-btn {
  @apply px-4 py-2 mr-3 rounded-md border-[1px] flex flex-col items-center justify-center cursor-pointer transition-all hover:border-secondary1 bg-bg-dark-light;
}

.active-btn-active {
  @apply px-4 py-2 mr-3 rounded-md border-[1px] flex flex-col items-center justify-center cursor-pointer transition-all hover:border-secondary1 bg-secondary1;
}

.custom-input {
  @apply w-full border-[1px] outline-none text-2xl p-4 py-2 rounded-md bg-bg-dark-medium;
}

.popup-container {
  @apply w-[50rem] p-6 rounded-md bg-bg-dark-medium border-2 border-secondary1 flex flex-col space-y-4;
}
