.fade-in {
  animation: fadeIn forwards 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut forwards 0.5s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icon-hover:hover * {
  color: #00d3c5;
}

.icon-hover-active * {
  color: #00d3c5;
}

.ant-select-dropdown {
  background-color: #011E30 !important;
  border: 1.5px solid #ffffff1e;
}

.ant-select-item.ant-select-item-option {
  background-color: #011E30;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: #1E293B;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #00D3C5;
}
